import React, { useState } from 'react'
import Index from '../../../Assets/images/Index'
import InnerPageBanner from '../../Common/InnerPageBanner/InnerPageBanner'
import { Fade } from 'react-reveal'
import { Helmet } from "react-helmet";
export default function Contact({ blueBoxRef, executeScroll, seo }) {
    const [alerts, setAlerts] = useState({
        show: false,
        type: "success",
        msg: ""
    })
    const [value, setvalue] = useState({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        brief: "",
    })
    const handleChange = (e) => {
        setvalue({ ...value, [e.target.name]: e.target.value })
    }

    const [errors, setErrors] = useState(false)
    const [btnLoader, setbtnLoader] = useState(false)
    const sendEmail = async (e) => {
        e.preventDefault();
        setbtnLoader(true)
        if (value.fname == "" || value.email == "") {
            setbtnLoader(false)
            setErrors(true)
        } else {
            // window.Email.send({
            //     Host: "smtp.elasticemail.com",
            //     Port: "2525",
            //     Username: "bsboby@gmail.com",
            //     Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
            //     From: "bsboby@gmail.com",
            //     To: "support@psd2html5.co",
            //     Subject: "Liquid web developers | Consultation Request Received",
            //     Body: `<p>Name: ${value?.fname} ${value?.lname}</p>
            // <p>Email: ${value?.email}</p>
            // <p>Skype ID or phone number: ${value?.phone}</p>
            // <p>Project brief: ${value?.brief}</p>
            // `,
            // }).then((res) => {
            //     if (res == "OK") {
            //         setAlerts({ show: true, type: "success", msg: "Email sent successfully" })
            //         setvalue({
            //             name: "",
            //             email: "",
            //             phone: "",
            //             budget: "",
            //             brief: "",
            //         })
            //         setErrors(false)
            //         setbtnLoader(false)
            //         window.Email.send({
            //             Host: "smtp.elasticemail.com",
            //             Port: "2525",
            //             Username: "bsboby@gmail.com",
            //             Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
            //             From: "bsboby@gmail.com",
            //             To: value?.email,
            //             Subject: "Thanks for reaching us!",
            //             Body: `<p>Thanks for reaching us!</p>
            //             <p>One of our technical experts will connect with you soon.</p>`,
            //         }).then();
            //         setTimeout(() => {
            //             setAlerts({ show: false, type: "success", msg: "" })
            //         }, 3000);
            //     } else {
            //         setAlerts({ show: true, type: "error", msg: "Email not sent." })
            //     }
            // });
        
            await fetch("https://api.liquidwebdevelopers.com/send-email", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    from: "support@liquidwebdevelopers.com",
                    to: "support@psd2html5.co",
                    subject: "Liquid web developers | Consultation Request Received",
                    body: `<p>Name: ${value?.fname} ${value?.lname}</p>
                           <p>Email: ${value?.email}</p>
                           <p>Skype ID or phone number: ${value?.phone || "NA"}</p>
                           <p>Project brief: ${value?.brief || "NA"}</p>
                           `
                }),
            }).then((res) => {
                console.log("res", res)
                if (res.ok) {
                    setAlerts({ show: true, type: "success", msg: "Email sent successfully" })
                    setErrors(false)
                    setbtnLoader(false)
                    fetch("https://api.liquidwebdevelopers.com/send-email", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            from: "support@liquidwebdevelopers.com",
                            to: value?.email,
                            subject: "Contact Us - We're Here to Help!",
                            body: `<p>Dear ${value?.fname} ${value?.lname},</p>
                                  <p>Thank you for reaching out to us! We appreciate your interest and will get back to you as soon as possible.</p>
                                  <p>We look forward to assisting you!</p>
                                  <p></p>
                                  <p>Best regards,</p>
                                  <p>Team Liquid Web Developers</p>
                                  <p>support@liquidwebdevelopers.com</p>
                                  <p><a href="https://www.liquidwebdevelopers.com/">https://www.liquidwebdevelopers.com/</a></p>
                                  `,
                        }),
                    }).then((res2) => {
                        if (res2.ok) {
                            setvalue({
                                fname: "",
                                lname: "",
                                email: "",
                                phone: "",
                                brief: "",
                            })
                        }
                    })
                } else {
                    setAlerts({ show: true, type: "error", msg: "Email not sent." })
                }
            })

        }
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/contact" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>

            <InnerPageBanner
                bannerClass="contact_banner"
                heading={`<span class="highlight_text">Contact </span> Us <span className="home_icon"> 👨‍💻 </span>`}
                subtitle="Need our help? Feel free to reach us at any time."
                bannerImg={Index.contactBannerImg}
                leftCol="col-md-6 col-lg-7"
                rightCol="col-md-6 col-lg-5"

            />

            {/* <!--Banner End--> */}
            <section className="bannerBtm_blue greenBg commonSection position-relative contact_topList">
                <div className="container">
                    <div className="startShopifyStore__form row justify-content-center">
                        <div className="col-12  col-md-4 col-lg-5">
                            <div className="contactlist__inner d-flex wow fadeInUp">
                                <div className="contact__icons">
                                    <img src={Index.blackBackClosedEnvelopeShape} alt="" />
                                </div>
                                <div className="contact__text">
                                    <span>Email</span>
                                    <h5 className="medium white_clr"> <a href="mailto:hello@liquidwebdevelopers.com">hello@liquidwebdevelopers.com</a> </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-12  col-md-4 col-lg-3">
                            <div className="contactlist__inner d-flex wow fadeInUp">
                                <div className="contact__icons">
                                    <img src={Index.skypeSvgrepo} alt="" />
                                </div>
                                <div className="contact__text">
                                    <span>Skype</span>
                                    <h5 className="medium white_clr">
                                        <a href="skype:james_weo?call">james_weo</a> </h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12  col-md-4 col-lg-4 ">
                            <div className="contactlist__inner d-flex wow fadeInUp mb-0">
                                <div className="contact__icons">
                                    <img src={Index.whitecall1} alt="" />
                                </div>
                                <div className="contact__text">
                                    <span>Phone</span>
                                    <h5 className="medium white_clr"> <a href="tel:+17147952324">+1 714 795 2324 </a> </h5>
                                </div>
                            </div>
                        </div> */}

                    </div>

                </div>
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="buble_box circle white_buble right_buble"></div>
                <div className="buble_box circle white_buble small_buble"></div>
            </section>
            <section className="choosing_contact commonSection" id="getstarted" ref={blueBoxRef}>
                <div className="container">
                    <div className="heading_section text-center">
                        <h3 className="extrabold pb-2">Get <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeOffer})` }}
                        >Free</span> Consultation
                        </h3>

                    </div>
                    <div className="startShopifyStore outer_glow">
                        <div className="row  justify-content-between">

                            <div className="col-md-12">
                                <div>
                                    <div className="startShopifyStore__form row  wow fadeInUp">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className={`form-control ${errors && value.fname == "" ? "error" : ""}`} placeholder="First Name*" name='fname' value={value.fname} onChange={(e) => handleChange(e)} required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Last Name" name='lname' value={value.lname} onChange={(e) => handleChange(e)} />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" className={`form-control ${errors && value.email == "" ? "error" : ""}`} placeholder="Email Address*" name='email' value={value.email} onChange={(e) => handleChange(e)} required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="tel" className="form-control" placeholder="Skype ID or Phone No." name='phone' value={value.phone} onChange={(e) => handleChange(e)} required />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea className="form-control" id="" cols="20" rows="8" name='brief' value={value.brief} onChange={(e) => handleChange(e)} placeholder="Project Description"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {btnLoader ?
                                                <button className="common_btn d-block">Sending...</button>
                                                :
                                                <button className="common_btn d-block" onClick={sendEmail}>{alerts.show ? alerts.msg : "Lets Get Started"}</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
